// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllDataGroupes = createAsyncThunk('appUsers/getAllDataGroupes', async () => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/groupes/all')
  return response.data
})

export const getDataGroupes = createAsyncThunk('appUsers/getDataClients', async params => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/groupes/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getGroupe = createAsyncThunk('appUsers/getGroupe', async id => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/groupes/groupe', { params: { id} })
  console.log(response.data)
  return response.data
})


export const addGroupes = createAsyncThunk('appUsers/addGroupes', async (groupe, { dispatch, getState }) => {
  
  await axios.post('https://apiv3dev.opentlv.com/api/v3/groupes/addgroupes', groupe)

  await dispatch(getDataGroupes(getState().groupes.params))
  
  return groupe
})

export const deleteClient = createAsyncThunk('appUsers/deleteClient', async (id, { dispatch, getState}) => {
  console.log("getState()")
  console.log(getState)

  await axios.delete('https://apiv3dev.opentlv.com/api/v3/clients/client/delete', {params: {id}})
//  await dispatch(getData(getState().users.params))
await dispatch(getDataClients(getState().groupes.params))

  return id
})
export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataGroupes.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getDataGroupes.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appClientsSlice.reducer
