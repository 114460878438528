// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'


export const getAllMedias = createAsyncThunk('appUsers/getAllMedias', async id  => {
    const response = await axios.get('https://apiv3dev.opentlv.com/interfacev2/medias/all', { params: { id} })
    return response.data
  })
  
  export const getMedias = createAsyncThunk('appUsers/getMedias', async params => {
    const response = await axios.get('https://apiv3dev.opentlv.com/interfacev2/medias/data', {params})
    return {
      params,
      data: response.data.data,
      totalPages: response.data.total
    }
  })
  export const getCatalogs = createAsyncThunk('appUsers/getCatalogs', async id => {
    const response = await axios.get('https://apiv3dev.opentlv.com/interfacev2/catalogs/all', { params: { id} })
  
      return response.data

  })
  export const getAllCampagnes = createAsyncThunk('appUsers/getAllMedias', async id  => {
    const response = await axios.get('https://apiv3dev.opentlv.com/interfacev2/campagnes/all', { params: { id} })
    return response.data
  })
  export const responseSendMedia = createAsyncThunk('appUsers/sendMedia', async (response) => {

    return {
      responseValidation : response
    
    }

  })

  export const addcatalog = createAsyncThunk('appUsers/addCatalog', async (obj, { dispatch, rejectWithValue}) => {
    //const formData = new FormData()
    console.log(obj)
   //formData.append('id_channel', obj.channel_id) 
   //formData.append('name', obj.name) 
   //formData.append('description', obj.description) 

   const response = await axios.post('https://apiv3dev.opentlv.com/interfacev2/addcatalog', obj, {})
 
   // getCatalogs


  })
 // $catalogs = Catalog::whereIn('id',$catalogslist)-get();

  
  export const addmedia = createAsyncThunk('appUsers/addMedia', async (obj, { dispatch, rejectWithValue}) => {
    const formData = new FormData()
    formData.append('file', obj.file) 
    formData.append('id_channel', obj.channel_id) 
    formData.append('secteur', obj.secteur) 
    formData.append('catalog', obj.catalog) 
    formData.append('typologie', obj.typologie) 
    formData.append('langue', obj.langue) 
    
    try {
      console.log(obj)
    const response = await axios.post('https://apiv3dev.opentlv.com/interfacev2/addmedia', formData, {})

    console.log(response)
    if (!response.data.success && !response.data.original) {

      console.log('la')
      console.log(response.data.success)

      return rejectWithValue(response.data)
  }
         await dispatch(getAllMedias(obj.channel_id))
        return response.data
  //  return response.data

} catch (error) {
  console.log('error')
  console.log(error)
  throw rejectWithValue(error.message)
}
  })
  export const editMedia = createAsyncThunk('appUsers/editMedia', async (obj, { dispatch, getState, rejectWithValue}) => {
    const formData = new FormData()
    console.log(getState().mediasInterface)
    formData.append('id', obj.id) 

    formData.append('id_channel', obj.channel_id) 
    formData.append('secteur', obj.secteur) 
    formData.append('catalog', obj.catalog) 
    formData.append('typologie', obj.typologie) 
    formData.append('langue', obj.langue) 
    
    try {
      console.log(obj)
    const response = await axios.post('https://apiv3dev.opentlv.com/interfacev2/edit/media', formData, {})
    await dispatch(getMedias(getState().mediasInterface.params))

    console.log(response)
    if (!response.data.success && !response.data.original) {

      console.log('la')
      console.log(response.data.success)

      return rejectWithValue(response.data)
  }
         await dispatch(getMedias(getState().mediasInterface.params))
        return response.data
  //  return response.data

} catch (error) {
  console.log('error')
  console.log(error)
  throw rejectWithValue(error.message)
}
  })

  export const validatemedia = createAsyncThunk('appUsers/validateMedia', async (obj, { dispatch, rejectWithValue}) => {
    console.log("send")
    console.log(obj)
    const formData = new FormData()
    formData.append('file', obj.file) 
    formData.append('id_channel', obj.channel_id) 
     
    try {

    const response = await axios.post('https://apiv3dev.opentlv.com/interfacev2/validatemedia', formData, {headers: {
      'Content-Type': 'multipart/form-data'
    }})

    console.log(response)
    if (!response.data.success && !response.data.original) {

      console.log('la')
      console.log(response.data.success)

      return rejectWithValue(response.data)
  }
         await dispatch(getAllMedias(obj.channel_id))
        return response.data
  //  return response.data

} catch (error) {
  console.log('error')
  console.log(error)
  throw rejectWithValue(error.message)
}
   
  
    //console.log(response)
  })

  export const getCampagnes = createAsyncThunk('appUsers/getMedias', async params => {
    const response = await axios.get('https://apiv3dev.opentlv.com/interfacev2/campagnes/data', params)
    return {
      params,
      data: response.data,
      totalPages: response.data.total
    }
  })


export const deleteMedia = createAsyncThunk('appUsers/deleteMedia', async (id, { dispatch, getState }) => {

  console.log("deleteMedia")
  console.log(id)
  
    await axios.delete('https://apiv3dev.opentlv.com/interfacev2/media/delete',  {params: {id}})

    await dispatch(getAllMedias())
    
    await dispatch(getMedias(getState().mediasInterface.params))
    
    await dispatch(getAllMedias())

    return id
  
  })

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    campagnes : [],
    total: 1,
    params: {},
    allData: [],
    allCampagnes: [],
    selectedUser: null,
    responseValidation : [],
    catalogs:[]
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(getMedias.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getAllMedias.fulfilled, (state, action) => {
        state.allData = action.payload
      })
       .addCase(validatemedia.rejected, (state, action) => {
        console.log("reject")
        state.responseValidation = action.payload
      })
      .addCase(getCatalogs.fulfilled, (state, action) => {
        state.catalogs = action.payload
      })
      .addCase(validatemedia.fulfilled, (state, action) => {
        console.log("reject")
        state.data = action.payload
//        console.log(action.payload)
      })
      .addCase(editMedia.fulfilled, (state, action) => {
        console.log("ok")
        state.data = action.payload
//        console.log(action.payload)
      })

      .addCase(editMedia.rejected, (state, action) => {
        console.log("reject")
        state.responseValidation = action.payload
      })
      .addCase(addmedia.fulfilled, (state, action) => {
        console.log("reject")
        state.data = action.payload
//        console.log(action.payload)
      })

      .addCase(addmedia.rejected, (state, action) => {
        console.log("reject")
        state.responseValidation = action.payload
      })

  }
})

export default appUsersSlice.reducer
