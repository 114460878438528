// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'


  export const getAllCampagnes = createAsyncThunk('appUsers/getAllCampagnes', async id  => {
    const response = await axios.get('https://apiv3dev.opentlv.com/interfacev2/campagnes/all', { params: { id} })
    //state.selectedChannelId = id
    return response.data
  })

  export const getAllListeSites = createAsyncThunk('appUsers/getAllListeSites', async id  => {
    const response = await axios.get('https://apiv3dev.opentlv.com/interfacev2/listesites/all', { params: { id} })
    //state.selectedChannelId = id
    return response.data
  })

  export const getPlaylistChannel = createAsyncThunk('appUsers/getPlaylistChannel', async id  => {
    const response = await axios.get('https://apiv3dev.opentlv.com/interfacev2/playlist/channel', { params: { id} })
    //state.selectedChannelId = id
    return response.data


  })
  export const getAlertes = createAsyncThunk('appUsers/getAlertes', async id  => {
    const response = await axios.get('https://apiv3dev.opentlv.com/interfacev2/alertes', { params: { id} })
    //state.selectedChannelId = id
    return response.data
  })
 
  export const getBoucles = createAsyncThunk('appUsers/getBoucles', async id  => {
    const response = await axios.get('https://apiv3dev.opentlv.com/interfacev2/boucles/playlist/global', { params: { id} })
    //state.selectedChannelId = id
    return response.data
  })
  export const getStatsSupport = createAsyncThunk('appUsers/getStatsSupport', async id  => {
    const response = await axios.get('https://apiv3dev.opentlv.com/interfacev2/stats/support', { params: { id} })
    //state.selectedChannelId = id
    return response.data
  })
  

  export const getAllOptions = createAsyncThunk('appUsers/getAllOptions', async id  => {
    const response = await axios.get('https://apiv3dev.opentlv.com/interfacev2/options/all', { params: { id} })
    //state.selectedChannelId = id
    return response.data

  })
  export const getCampagnes = createAsyncThunk('appUsers/getCampagnes', async params => {
    const response = await axios.get('https://apiv3dev.opentlv.com/interfacev2/campagnes/data', params)
    return {
      params,
      campagnes: response.data,
      totalPages: response.data.total
    }
  })
  export const sendCampagne = createAsyncThunk('appChat/sendMsg', async (obj, { dispatch }) => {
    console.log("send")
    console.log(obj)
    
    const response = await axios.post('https://apiv3dev.opentlv.com/interfacev2/campagne', obj)
    await dispatch(getAllCampagnes(obj.channel_id))


    return response.data
  })
  
  export const getGlobalEvents = createAsyncThunk('appUsers/getGlobalEvents', async id  => {
    const response = await axios.get('https://apiv3dev.opentlv.com/interfacev2/events/global', { params: { id} })
    //state.selectedChannelId = id
    return response.data
  })

  export const editCampagne = createAsyncThunk('appUsers/editCampagne', async (obj, { dispatch }) => {
    console.log("edit")
    console.log(obj)
    
    const response = await axios.post('https://apiv3dev.opentlv.com/interfacev2/edit/campagne', obj)
    await dispatch(getAllCampagnes(obj.channel_id))
    await dispatch(getGlobalEvents(parseInt(obj.channel_id)))


    return response.data
  })

  
  export const affiliateMedia = createAsyncThunk('appUsers/affiliateMedia', async (obj, { dispatch, getState }) => {
    console.log("send")
    console.log(obj)
    console.log(getState())

    
    const response = await axios.post('https://apiv3dev.opentlv.com/interfacev2/affiliatemedia', obj)
    const id = obj.channel
    const campagne_id  = obj.campagne
    await dispatch(getAllCampagnes(obj.channel))
    await dispatch(getAllListeSites(campagne_id))
    await dispatch(getGlobalEvents(parseInt(id)))

  
    return response.data
  })

export const deleteCampagne = createAsyncThunk('appUsers/deleteCampagne', async (params, { dispatch }) => {
    await axios.delete('https://apiv3dev.opentlv.com/interfacev2/campagne/delete', {params})
//
  //  await dispatch(getCampagnes(getState().campagnes.params))
    await dispatch(getAllCampagnes(params.channel_id))
    await dispatch(getGlobalEvents(parseInt(params.channel_id)))

    return response.data

  })

  export const deleteMediaCampagne = createAsyncThunk('appUsers/deleteMediaCampagne', async (params, { dispatch }) => {
    await axios.delete('https://apiv3dev.opentlv.com/interfacev2/affiliate/delete', {params})
//
  //  await dispatch(getCampagnes(getState().campagnes.params))
    await dispatch(getAllCampagnes(params.channel_id))
    await dispatch(getGlobalEvents(parseInt(params.channel_id)))

    return response.data

  })

  export const updatePlaylist = createAsyncThunk('appUsers/updatePlaylist ', async (obj, { dispatch }) => {
    
    await dispatch(getPlaylistChannel(params.channel_id))
    
  })

export const appCampagneSlice = createSlice({
  name: 'appCampagnes',
  initialState: {
    data: [],
    campagnes: [],
    total: 1,
    params: {},
    allData: [],
    allCampagnes: [],
    allOptions: [],
    listeSites:[],
    playlistChannel:[],
    statsSupport:[],
    alertes:[],
    selectedChannelId: null,
    eventsGLobal : [],
    boucles : []
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(getCampagnes.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
    .addCase(getAllCampagnes.fulfilled, (state, action) => {
      state.allCampagnes = action.payload
    })
    .addCase(getBoucles.fulfilled, (state, action) => {
      state.boucles = action.payload
    })
    .addCase(getPlaylistChannel.fulfilled, (state, action) => {
      state.playlistChannel = action.payload.playlist
      state.channel = action.payload.channel


    })

    .addCase(getStatsSupport.fulfilled, (state, action) => {
      state.statsSupport = action.payload
    })
    .addCase(getAlertes.fulfilled, (state, action) => {
      state.alertes = action.payload
    })
    .addCase(getAllListeSites.fulfilled, (state, action) => {
      if (state.listeSites.includes(action.payload)) {
       // state.listeSites.splice(state.selectedCalendars.indexOf(action.payload), 1)
      } else {
        //  console.log(action.payload)
        state.listeSites[action['meta']['arg']] = action.payload
      }
//      state.listeSites = action.payload
    })
 .addCase(getAllOptions.fulfilled, (state, action) => {
      state.allOptions = action.payload
    })
  }
})

export default appCampagneSlice.reducer