// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllDataOptionsZones = createAsyncThunk('appUsers/getAllDataOptionsZones', async id => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/zones/all',  { params: { id} })
  return response.data
})

export const getDataOptionsZones = createAsyncThunk('appUsers/getDataOptionsZones', async params => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/zones/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})
export const getAllDataOptionZones = createAsyncThunk('appUsers/getAllDataOptionZones', async id => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/client/option/zones/all',  { params: { id} })
  return response.data
})

export const getDataOptionZones = createAsyncThunk('appUsers/getDataOptionZones', async params => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/client/option/zones/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/users/user', { params: { id} })
  console.log(response.data)
  return response.data
})
export const addZoneToOption = createAsyncThunk('appUsers/addZoneToOption', async (option, { dispatch, getState }) => {
  //  option.client_id = getState()
    console.log("addSite")
    
    option.option_id = getState().clientOptionSites.selectedOption.id
    console.log(option)
  
    console.log(getState().clientOptionSites.selectedOption)
    
    await axios.post('https://apiv3dev.opentlv.com/api/v3/client/addzonetooption', option)
    await dispatch(getDataOptionsClient(getState().sitesClient.params))
  
    //await d
  })
export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataOptionsZones.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getDataOptionsZones.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getAllDataOptionZones.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getDataOptionZones.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appClientsSlice.reducer
