// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'


export const getAllDataGares = createAsyncThunk('appUsers/getAllDataGares', async id  => {
    const response = await axios.get('https://apiv3dev.opentlv.com/sncf/gares', { params: { id} })
    return response.data
  })
  
  export const getDataGares = createAsyncThunk('appUsers/getDataGares', async params => {
    const response = await axios.get('https://apiv3dev.opentlv.com/sncf/data', {params})
    console.log(response.data)
  
    return {
      params,
      data: response.data.data,
      totalPages: response.data.total
    }
  })
  export const addGare = createAsyncThunk('appUsers/addGare', async (gare, { dispatch, getState }) => {
    //channel.client_id = getState().sitesClient.params.id  
    await axios.post('https://apiv3dev.opentlv.com/sncf/add/gare', gare)
  
    //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
    await dispatch(getDataGares(getState().gares.params))
    return gare
  })
  
  export const editGare = createAsyncThunk('appUsers/editGare', async (gare, { dispatch, getState }) => {
    //channel.client_id = getState().sitesClient.params.id
  
  
    await axios.post('https://apiv3dev.opentlv.com/sncf/edit/gare', gare)
  
    //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
    await dispatch(getDataGares(getState().gares.params))
    return gare
  }) 
export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataGares.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getDataGares.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appUsersSlice.reducer
