// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'


export const getAllData = createAsyncThunk('appUsers/getAllDataGares', async id  => {
    const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/cameras/all', { params: { id} })
    return response.data
  })
  
  export const getData = createAsyncThunk('appUsers/getData', async params => {
    const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/cameras/data', {params})
    console.log(response.data)
  
    return {
      params,
      data: response.data.data,
      totalPages: response.data.total
    }
  })
  export const addGare = createAsyncThunk('appUsers/addGare', async (gare, { dispatch, getState }) => {
    //channel.client_id = getState().sitesClient.params.id
    console.log(getState().sitesClient)
  
  
    await axios.post('https://apiv3dev.opentlv.com/sncf/addgare', gare)
  
    //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
    await dispatch(getDataChannelsClient(getState().sitesClient.params))
    return gare
  })
  
  
export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appUsersSlice.reducer
