// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllDataCamerasSite = createAsyncThunk('appUsers/getAllDataCamerasSite', async id => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/site/cameras/all',  { params: { id} })
  return response.data
})

export const getDataCamerasSite = createAsyncThunk('appUsers/getDataCamerasSite', async params => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/site/cameras/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getPlayerTypes = createAsyncThunk('appUsers/getPlayerTypes', async () => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/player/types')
  return response.data
})
export const getMultiZones = createAsyncThunk('appUsers/getMultiZones', async () => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/player/multi/zones')
  return response.data
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/users/user', { params: { id} })
  console.log(response.data)
  return response.data
})

export const getPlayer = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/users/user', { params: { id} })
  console.log(response.data)
  return response.data
})


export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const addCamera = createAsyncThunk('appUsers/addCamera', async (site, { dispatch, getState }) => {
  console.log("app")

  site.id_site = getState().clients.selectedSite.id
  console.log(site)

  await axios.post('https://apiv3dev.opentlv.com/api/v3/site/camera/add', site)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  await dispatch(getDataCamerasSite(getState().playersSite.params))
  return site
})

export const editPlayer = createAsyncThunk('appUsers/editPlayer', async (site, { dispatch, getState }) => {
  console.log("app")

  site.id_site = getState().clients.selectedSite.id
  console.log(site)


  await axios.post('https://apiv3dev.opentlv.com/api/v3/site/player/edit', site)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  await dispatch(getDataPlayerSite(getState().playersSite.params))
  return site
})
export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    multiZones: []

  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataCamerasSite.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getDataCamerasSite.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getPlayerTypes.fulfilled, (state, action) => {
        state.playerTypes = action.payload
      })
      .addCase(getMultiZones.fulfilled, (state, action) => {
        state.multiZones = action.payload
      })


  }
})

export default appClientsSlice.reducer
