// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getChannelPlaylists = createAsyncThunk('appUsers/getChannelPlaylists', async id => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/channel/playlists/all', { params: { id} })
  console.log(response)

  return response.data
})

export const getChannelPlaylistsData = createAsyncThunk('appUsers/getChannelPlaylistsData', async params => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/channel/playlists/data', {params})
 
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})
export const getChannelPlaylistsActiveData = createAsyncThunk('appUsers/getChannelPlaylistsActiveData', async params => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/channel/playlists/active/data', {params})
 
  return {
    paramsActive,
    dataActive: response.data.data,
    totalPagesActive: response.data.total
  }
})


export const addPlaylist = createAsyncThunk('appUsers/addPlaylist', async (playlist, { dispatch, getState }) => {
  playlist.client_id = getState().sitesClient.params.id
  console.log(getState().sitesClient)


  await axios.post('https://apiv3dev.opentlv.com/api/v3/client/addplaylist', playlist)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  await dispatch(getDataPlaylistsClient(getState().sitesClient.params))
  return playlist
})

export const addPlaylistChannel = createAsyncThunk('appUsers/addPlaylistChannel', async (playlist, { dispatch, getState }) => {

  playlist.channel_id = getState().channelCatalogs.selectedChannel.id

await axios.post('https://apiv3dev.opentlv.com/api/v3/client/channel/addplaylist', playlist)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  await dispatch(getChannelPlaylistsData(getState().channelPlaylists.params))
  return playlist
})

export const selectPlaylistChannel = createAsyncThunk('appUsers/selectPlaylistChannel', async (playlist, { dispatch, getState }) => {

  playlist.channel_id = getState().channelCatalogs.selectedChannel.id

await axios.post('https://apiv3dev.opentlv.com/api/v3/channel/playlist/active', playlist)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  await dispatch(getChannelPlaylistsData(getState().channelPlaylists.params))
  return playlist
})
export const deletePlaylist = createAsyncThunk('appUsers/deletePlaylist', async (id, { dispatch, getState }) => {
  console.log("getState()")
  console.log(id)

  await axios.delete('https://apiv3dev.opentlv.com/api/v3/client/playlist/delete', {params: {id}})
//  await dispatch(getData(getState().users.params))
await dispatch(getChannelPlaylistsData(getState().channelPlaylists.params))

  return id
})
export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    dataActive: [],
    totalPagesActive: 1,
    paramsActive: {}
  },
  
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getChannelPlaylists.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getChannelPlaylistsData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getChannelPlaylistsActiveData.fulfilled, (state, action) => {
        state.dataActive = action.payload.data
        state.paramsActive = action.payload.params
        state.totalActive = action.payload.totalPages
      })
  }
})

export default appClientsSlice.reducer
