// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllDataClientsChannels = createAsyncThunk('appUsers/getAllDataClientsChannels', async (id) => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/user/channels/all', { params: { id} })
  return response.data
})

export const getDataClientsChannels = createAsyncThunk('appUsers/getDataClientsChannels', async params => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/user/channels/data', { params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const addUserToChannel = createAsyncThunk('appUsers/addUserToChannel', async (group, { getState }) => {
  //  option.client_id = getState()
    console.log("addPrivilgeToGroup")
    
    group.user_id = getState().users.selectedUser.id
    
    
    await axios.post('https://apiv3dev.opentlv.com/api/v3/user/add/user/channel', group)
    //.wait dispatch(getGroupPrivilegesData(getState().groupPrivileges.params))
  
    //await d
  })


export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataClientsChannels.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getDataClientsChannels.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appUsersSlice.reducer

/*public function getUserOptions(Request $request){
  $values = $request->all();

  $user =  JWTAuth::parseToken()->authenticate();

  //user has Groupes
  // user has CLients

  $userHasGroupes = UsersHasGroupes::where('id_user','=',$request->get('id'))->pluck("id_groupe");

  $channelsGroupe = Channel::whereIn('id_groupe',$userHasGroupes)->pluck('id');

  $userHasClients = UsersHasCompanies::where('id_user','=',$request->get('id'))->pluck("id_company");

  $channelsClient = Channel::whereIn('id_client',$userHasClients)->pluck("id");


//            $channelsGroupe->merge($channelsClient);

                $mergedChannels = $channelsGroupe->merge($channelsClient);

$zones = Channel::whereIn('id',$mergedChannels)->with(['has_user' => function ($query) use ($values){
$query->where('id_user','=',$values['id']);

  }])->get(['name','id']);

  return response()->json($zones);
}

public function getUserOptionsData(Request $request){

  $values = $request->all();
  $optionsFinal = [];


    $user =  JWTAuth::parseToken()->authenticate();

        //user has Groupes
        // user has CLients

        $userHasGroupes = UsersHasGroupes::where('id_user','=',$request->get('id'))->pluck("id_groupe");
        $channelsGroupe = Channel::whereIn('id_groupe',$userHasGroupes)->pluck('id');
        $userHasClients = UsersHasCompanies::where('id_user','=',$request->get('id'))->pluck("id_company");
        $channelsClient = Channel::whereIn('id_client',$userHasClients)->pluck("id");

  
        $mergedChannels = $channelsGroupe->merge($channelsClient);

        $channel = Channel::whereIn('id', $mergedChannels);

        foreach($channels as $channel ){

          if ($channel->id_groupe) {

          $options_groupe_liste = OptionsGroupe::where('id_groupe','=',$channel->id_groupe)->pluck('id');
          $clients = Client::where('id_groupe','=',$channel->id_groupe)->pluck('id');
          $options_client_liste = OptionsClient::whereIn('id_client',$clients)->pluck('id');
          $list_options = $options_client_liste->merge($options_groupe_liste);
          $options = Option::whereIn('id',$list_options)->pluck('id');
    
          array_push($optionsFinal, options);

            }  

       if ($channel->id_client) {

       $optionslist = OptionsClient::where('id_client','=',$channel->id_client)->pluck('id');
       $groupe_id  = Client::find($channel->id_client)->first();
       $options_groupe = OptionsGroupe::where('id_groupe','=',$groupe_id->id_groupe)->pluck('id');
       $liste_option = $optionslist->merge($options_groupe);
       $options = Option::whereIn('id',$liste_option)->pluck('id');   
       array_push($optionsFinal, options);

       
      }

      }
      if($values['q'] != NULL){
          $searchTerm = $values['q'];

      $zones = Option::whereIn('id',$optionsFinal)->where('name','LIKE',"%$searchTerm%")->with(['has_user' => function ($query) use ($values){
                      $query->where('id_user','=',$values['id']);
                      }])->orderBy($values['sortColumn'],$values['sort'])->paginate($values['perPage']);

  }else{
  $zones = Option::whereIn('id',$optionsFinal)->with(['has_user' => function ($query) use ($values) {
      $query->where('id_user','=',$values["id"]);
      }])->orderBy($values['sortColumn'], $values['sort'])->paginate($values['perPage']);
    
}

  return response()->json($zones);

}

$user =  JWTAuth::parseToken()->authenticate();

     $channel = Channel::find($request->get('id'));

     if ($channel->id_groupe) {

        $options_groupe_liste = OptionsGroupe::where('id_groupe','=',$channel->id_groupe)->pluck('id');
        $clients = Client::where('id_groupe','=',$channel->id_groupe)->pluck('id');
        $options_client_liste = OptionsClient::whereIn('id_client',$clients)->pluck('id');


        $list_options = $options_client_liste->merge($options_groupe_liste);


        $options = Option::whereIn('id',$list_options)->get(['name as label', 'id as value']);

        //$options_client = Option::whereIn('id',$options_client_liste)->get(['name as label', 'id as value']);


        //$options = $options_client->merge($options_groupe);

        Log::info("options");

        Log::info($options);
        Log::info($options_groupe_liste);

     }
     if ($channel->id_client) {

     $optionslist = OptionsClient::where('id_client','=',$channel->id_client)->pluck('id');
   //  Log::info($channel);
     $groupe_id  = Client::find($channel->id_client)->first();


    $options_groupe = OptionsGroupe::where('id_groupe','=',$groupe_id->id_groupe)->pluck('id');

     $liste_option = $optionslist->merge($options_groupe);

     $options = Option::whereIn('id',$liste_option)->get(['name as label','id as value']);

     Log::info($options);
 
     }
     return response()->json($options);

*/