// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllPdvs = createAsyncThunk('appUsers/getAllPdvs', async () => {
  const response = await axios.get('https://apiv3dev.opentlv.com/interfacev2/pdv/all')
  return response.data
})

export const getDataPdv = createAsyncThunk('appUsers/getDataPdv', async params => {
  const response = await axios.get('https://apiv3dev.opentlv.com/interfacev2/pdv/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})
export const getAllZones = createAsyncThunk('appUsers/getAllZones', async id => {
  console.log("getAllZones")

  console.log(id)
  const response = await axios.get('https://apiv3dev.opentlv.com/interfacev2/zones/all', { params: { id} })
  return response.data
})
export const addPdv = createAsyncThunk('appUsers/addPdv', async (pdv, { dispatch, getState }) => {

  await axios.post('https://apiv3dev.opentlv.com/interfacev2/addpdv', pdv)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  await dispatch(getDataPdv(getState().pdv.params))
  return playlist
})

export const addSupport = createAsyncThunk('appUsers/addSupport', async (obj, { dispatch, rejectWithValue}) => {
  const formData = new FormData()
  formData.append('file', obj.file) 
  formData.append('name', obj.name) 
  formData.append('description', obj.description) 
  formData.append('selectedZone', obj.selectedZone) 
  formData.append('currentFile', obj.currentFile) 
  formData.append('nomZone', obj.nomZone) 
  formData.append('isChecked', obj.isChecked) 

  formData.append('support_id', obj.support_id) 
  
  try {
    console.log(obj)
  const response = await axios.post('https://apiv3dev.opentlv.com/interfacev2/addsupport', formData, {})

  console.log(response)
  if (!response.data.success && !response.data.original) {

    console.log('la')
    console.log(response.data.success)

    return rejectWithValue(response.data)
}
       await dispatch(getAllPdvs())
      return response.data
//  return response.data

} catch (error) {
console.log('error')
console.log(error)
throw rejectWithValue(error.message)
}
})

export const addPlayer = createAsyncThunk('appUsers/addPlayer', async (obj, { dispatch, rejectWithValue}) => {
  const formData = new FormData()

  formData.append('name', obj.name) 
  formData.append('description', obj.description) 
  formData.append('selectedZone', obj.selectedZone) 
  formData.append('nomZone', obj.nomZone) 
  formData.append('isChecked', obj.isChecked) 
  formData.append('support_id', obj.support_id)   
  try {
    console.log(obj)
  const response = await axios.post('https://apiv3dev.opentlv.com/interfacev2/addplayer', formData, {})

  console.log(response)
  if (!response.data.success && !response.data.original) {

    console.log('la')
    console.log(response.data.success)

    return rejectWithValue(response.data)
}
       await dispatch(getAllPdvs())
      return response.data
//  return response.data

} catch (error) {
console.log('error')
console.log(error)
throw rejectWithValue(error.message)
}
})


export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    zones : [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllPdvs.fulfilled, (state, action) => {
        state.allData = action.payload
      
      })
      .addCase(getAllZones.fulfilled, (state, action) => {
        state.zones = action.payload
      
      })
      .addCase(getDataPdv.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(addSupport.fulfilled, (state, action) => {
        console.log("reject")
        state.data = action.payload
//        console.log(action.payload)
      })
      .addCase(addSupport.rejected, (state, action) => {
        console.log("reject")
        state.responseValidation = action.payload
      })
      .addCase(addPlayer.fulfilled, (state, action) => {
        console.log("reject")
        state.data = action.payload
//        console.log(action.payload)
      })
      .addCase(addPlayer.rejected, (state, action) => {
        console.log("reject")
        state.responseValidation = action.payload
      })
  }
})

export default appClientsSlice.reducer
