// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllDataConfigurationZones = createAsyncThunk('appUsers/getAllDataConfigurationZones', async id => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/configurations/all', { params: { id} })
  return response.data
})

export const getDataConfigurationZones = createAsyncThunk('appUsers/getDataConfigurationZones', async params => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/configurations/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})
export const addConfigToZone = createAsyncThunk('appUsers/addConfigToZone', async (config, { dispatch, getState }) => {
  // option.client_id = getState()
  console.log("add option to Zone")
  
  console.log(getState().configuration)
  
  config.config_id = getState().configuration.selectedConfiguration.id

  await axios.post('https://apiv3dev.opentlv.com/api/v3/zone/addconfigtozone', config)
  
  await dispatch(getDataConfigurationZones(getState().configuration.params))

  })

  export const editConfig = createAsyncThunk('appUsers/editConfig', async (site, { dispatch, getState }) => {
    console.log("app")
  
//    site.id_site = getState().clients.selectedSite.id
    console.log(site)
  
  
    await axios.post('https://apiv3dev.opentlv.com/api/v3/client/editconfig', site)
  
    //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
    await dispatch(getDataConfigurationZones(getState().configuration.params))
    return site
  })

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    client: [],
    selectedConfiguration: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataConfigurationZones.fulfilled, (state, action) => {
        state.allData = action.payload.zones
        state.client = action.payload.client
        state.selectedConfiguration = action.payload.configuration


      })
      .addCase(getDataConfigurationZones.fulfilled, (state, action) => {
       state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appClientsSlice.reducer
