// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getGroupPrivileges = createAsyncThunk('appUsers/getGroupPrivileges', async id => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/groups/privileges/all', { params: { id} })
  return response.data
})

export const getGroupPrivilegesData = createAsyncThunk('appUsers/getGroupPrivilegesData', async params => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/groups/privileges/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getAllDataPrivileges = createAsyncThunk('appUsers/getAllDataPrivileges', async id => {

  console.log("ID")

  console.log(id)
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/users/roles/all')
  return response.data
})

export const getDataPrivileges = createAsyncThunk('appUsers/getData', async params => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/users/roles/data', params)
  return {
    params,
    data: response.data,
    totalPages: response.data.total
  }
})
export const addPrivilgeToGroup = createAsyncThunk('appUsers/addPrivilgeToGroup', async (group, { dispatch, getState }) => {
  //  option.client_id = getState()
    console.log("addPrivilgeToGroup")
    
    group.group_id = getState().groupPrivileges.selectedGroup.id
    
    
    await axios.post('https://apiv3dev.opentlv.com/api/v3/users/addprivilegetogroup', group)
    await dispatch(getGroupPrivilegesData(getState().groupPrivileges.params))
  
    //await d
  })
export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/users/user', { params: { id} })
  console.log(response.data)
  return response.data
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedGroup: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getGroupPrivileges.fulfilled, (state, action) => {
        state.allData = action.payload.privileges
        state.selectedGroup  = action.payload.group
      })
      .addCase(getGroupPrivilegesData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appUsersSlice.reducer
