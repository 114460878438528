// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllDataPlaylistsSite = createAsyncThunk('appUsers/getAllDataPlaylistsSite', async id => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/site/playlists/all',  { params: { id} })
  return response.data
})

export const getDataPlaylistsSite = createAsyncThunk('appUsers/getDataPlaylistsSite', async params => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/site/playlists/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})
export const addPlaylist = createAsyncThunk('appUsers/addPlaylist', async (site, { dispatch, getState }) => {
  site.id_site = getState().clients.selectedSite.id
  

  await axios.post('https://apiv3dev.opentlv.com/api/v3/site/playlist/add', site)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  await dispatch(getDataPlaylistsSite(getState().playlistsSite.params))
  return site
})
export const deletePlaylist = createAsyncThunk('appUsers/deletePlaylist', async (id, { dispatch, getState }) => {


  await axios.delete('https://apiv3dev.opentlv.com/api/v3/site/playlist/delete', {params: {id}})

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  await dispatch(getDataPlaylistsSite(getState().playlistsSite.params))
  return site
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/users/user', { params: { id} })
  console.log(response.data)
  return response.data
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataPlaylistsSite.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getDataPlaylistsSite.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appClientsSlice.reducer
