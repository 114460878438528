// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllDataZonesPlayer = createAsyncThunk('appUsers/getAllDataZonesPlayer', async id => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/player/zones/all',  { params: { id} })
  return response.data
})

export const getDataZonesPlayer = createAsyncThunk('appUsers/getDataZonesPlayer', async params => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/player/zones/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const addZoneToPlayer = createAsyncThunk('appUsers/addZoneToPlayer', async (zone, { getState }) => {

  await axios.post('https://apiv3dev.opentlv.com/api/v3/player/add/zone', {zone_id : zone.zone_id, player_id: getState().playerCompnents.params.id})

  return zone

})
export const getManu = createAsyncThunk('appUsers/getManu', async id => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/component/player/manu', { params: { id} })
  console.log(response.data)
  return response.data

})

export const getProducts = createAsyncThunk('appUsers/getProducts', async id => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/component/player/product', { params: { id} })
  console.log(response.data)
  return response.data

})


export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/users/user', { params: { id} })
  console.log(response.data)
  return response.data
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    manus: [],
    products: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataZonesPlayer.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getDataZonesPlayer.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getManu.fulfilled, (state, action) => {
        state.manus = action.payload.manus
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.products = action.payload.products
      })


  }
})

export default appClientsSlice.reducer
