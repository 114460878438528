// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllSupports = createAsyncThunk('appUsers/getAllSupports', async (params) => {
  const response = await axios.get('https://apiv3dev.opentlv.com/interfacev2/supports/all', {params})
  return response.data
})


export const getAllClients = createAsyncThunk('appUsers/getAllClients', async (id) => {
  
  const response = await axios.get('https://apiv3dev.opentlv.com/interfacev2/clients/all', {params : { id }})
  
  return response.data
})

export const getAllGroupes = createAsyncThunk('appUsers/getAllGroupes', async () => {
  
  const response = await axios.get('https://apiv3dev.opentlv.com/interfacev2/groupes/all')
  
  return response.data
})

export const addBoucles = createAsyncThunk('appUsers/addBoucles', async (boucle) => {

  const response = await axios.post('https://apiv3dev.opentlv.com/interfacev2/addboucles', boucle, {})

  return response

})

export const getBoucles = createAsyncThunk('appUsers/getBoucles', async (id) => {
  
  const response = await axios.get('https://apiv3dev.opentlv.com/interfacev2/boucles', {params : { id }})
  
  return response.data
})
export const addSupport = createAsyncThunk('appUsers/addSupport', async (obj, { dispatch, rejectWithValue}) => {
  const formData = new FormData()
  formData.append('file', obj.file) 
  formData.append('name', obj.name) 
  formData.append('description', obj.description)
  formData.append('isChecked', obj.isChecked)
  formData.append('orientation', obj.orientation) 
  formData.append('client_id', obj.client_id) 
  formData.append('groupe_id', obj.groupe_id) 

  try {
    console.log(obj)
  const response = await axios.post('https://apiv3dev.opentlv.com/interfacev2/addsupport/client', formData, {})

  console.log(response)
  if (!response.data.success && !response.data.original) {

    console.log('la')
    console.log(response.data.success)

    return rejectWithValue(response.data)
}
if (obj.groupe_id) {
  
  await dispatch(getAllSupports(obj.groupe_id, "groupe"))
}

  if (obj.client_id) {
    await dispatch(getAllSupports(obj.client_id, "client"))
  }

      return response.data
//  return response.data

} catch (error) {
console.log('error')
console.log(error)
throw rejectWithValue(error.message)
}
})
export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    clients: [],
    groupes: [],
    boucles: [], 
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllSupports.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getAllClients.fulfilled, (state, action) => {
        state.clients = action.payload
      })
      .addCase(getAllGroupes.fulfilled, (state, action) => {
        state.groupes = action.payload
      })
      .addCase(getBoucles.fulfilled, (state, action) => {
        state.boucles = action.payload
      })
      .addCase(addSupport.fulfilled, (state, action) => {
        console.log("reject")
        state.data = action.payload
//        console.log(action.payload)
      })

      .addCase(addSupport.rejected, (state, action) => {
        console.log("reject")
        state.responseValidation = action.payload
      })
  }
})

export default appUsersSlice.reducer
