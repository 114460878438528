// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllDataClientsPlaylists = createAsyncThunk('appUsers/getAllDataClientsPlaylists', async () => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/clients/playlists/all')
  return response.data
})

export const getDataClientsPlaylists = createAsyncThunk('appUsers/getDataClientsPlaylists', async params => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/clients/playlists/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})


export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/users/user', { params: { id} })
  console.log(response.data)
  return response.data
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const addPlaylist = createAsyncThunk('appUsers/addPlaylist', async (playlist, { dispatch, getState }) => {

  await axios.post('https://apiv3dev.opentlv.com/api/v3/clients/addplaylist', playlist)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  await dispatch(getDataClientsPlaylists(getState().playlists.params))
  return playlist
})


export const getAllDatasPlaylistSlot = createAsyncThunk('appUsers/getAllDatasPlaylistSlot', async () => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/playlist/slots')

  return response.data
})

export const deletePlaylist = createAsyncThunk('appUsers/deletePlaylist', async (id, { dispatch, getState }) => {
  console.log("getState()")
  console.log(id)

  await axios.delete('https://apiv3dev.opentlv.com/api/v3/clients/playlist/delete', {params: {id}})
//  await dispatch(getData(getState().users.params))
    await dispatch(getDataClientsPlaylists(getState().playlists.params))

  return id
})
export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataClientsPlaylists.fulfilled, (state, action) => {
        state.allData = action.payload
      
      })
      .addCase(getDataClientsPlaylists.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appClientsSlice.reducer
