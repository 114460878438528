// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllDataMediasCatalog = createAsyncThunk('appUsers/getAllDataMediasCatalog', async id => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/catalog/players/all',  { params: { id} })
  return response.data
})

export const getDataMediasCatlaog = createAsyncThunk('appUsers/getDataMediasCatlaog', async params => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/catalog/players/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/users/user', { params: { id} })
  console.log(response.data)
  return response.data
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})
export const addmedia = createAsyncThunk('appUsers/addMedia', async (obj, { dispatch, getState, rejectWithValue }) => {
  

  const formData = new FormData()
  
  obj.files.forEach((file, index) => {
    formData.append('files[]', file)
  })
//  formData.append('file', obj.file) 
  formData.append('id_catalog', obj.id_catalog) 
  
  
  try {
    console.log(obj)
    console.log(getState().catalogMedias.params)
    console.log(rejectWithValue)

  const response = await axios.post('https://apiv3dev.opentlv.com/api/v3/catalog/addmedia', formData, {})

  console.log(response)
  if (!response.data.success && !response.data.original) {

    console.log('la')
    console.log(response.data.success)

    return rejectWithValue(response.data)
}
       await dispatch(getDataMediasCatlaog(getState().catalogMedias.params))
      return response.data
//  return response.data

} catch (error) {
console.log('error')
console.log(error)
throw rejectWithValue(error.message)
}
})

export const editmedia = createAsyncThunk('appUsers/editmedia', async (obj, { dispatch, getState, rejectWithValue }) => {
  

  const formData = new FormData()
  
  obj.files.forEach((file, index) => {
    formData.append('files[]', file)
  })
//  formData.append('file', obj.file) 
  formData.append('id_catalog', obj.id_catalog) 
  formData.append('selectedRowId', obj.selectedRowId) 
  
  
  try {
    console.log(obj)
    console.log(getState().catalogMedias.params)
    console.log(rejectWithValue)

  const response = await axios.post('https://apiv3dev.opentlv.com/api/v3/catalog/editmedia', formData, {})

  console.log(response)
  if (!response.data.success && !response.data.original) {

    console.log('la')
    console.log(response.data.success)

    return rejectWithValue(response.data)
}
       await dispatch(getDataMediasCatlaog(getState().catalogMedias.params))
      return response.data
//  return response.data

} catch (error) {
console.log('error')
console.log(error)
throw rejectWithValue(error.message)
}
})

export const deleteMediaCatalog = createAsyncThunk('appUsers/deleteMediaCatalog', async (id, { dispatch, getState }) => {
  console.log("getState()")
  console.log(id)

  await axios.delete('https://apiv3dev.opentlv.com/api/v3/catalog/media/delete', {params: {id}})
//  await dispatch(getData(getState().users.params))
await dispatch(getDataMediasCatlaog(getState().catalogMedias.params))

  return id
})

export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    media : [],
    selectedCatalog: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataMediasCatalog.fulfilled, (state, action) => {
        state.allData = action.payload.medias
        state.media = action.payload.media        
      })
      .addCase(getDataMediasCatlaog.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(addmedia.rejected, (state, action) => {
        console.log("reject")
        state.responseValidation = action.payload
      })
      .addCase(addmedia.fulfilled, (state, action) => {
        console.log("fulfilled")
        state.data = action.payload
//        console.log(action.payload)
      })
  }
})

export default appClientsSlice.reducer